<template>
    <div>
        <document-management
            :buttonPanelDetails="buttonPanelDetails"
            :counterDetails="counterDetails"
            :filterObject="filterObject"
            :documentManagementHeader="documentManagementHeader"
            :documentManagementRow="documentManagementRow"
            :menuwidth="menuwidth"
            :totalItems="totalData"
            :pageSize="pageSize"
            :page="page"
            :documentManagementListProgress="documentManagementListProgress"
            :sortType="sortType"
            :sortOrder="sortOrder"
            :sortColIndex="sortColIndex"
            :orderByColumn="orderByColumn"
            :paginationComponentKey="paginationComponentKey"
            @onClickPanelButton="onClickPanelButton"
            @rowDownloadActionButton="rowDownloadActionButton"
            @searchFilter="searchFilter"
            @dateRange="dateRange"
            @openFilterPanel="openFilterPanel"
            @handleSortEvent="handleSortEvent"
            @paginateTable="paginateTable"
            @clearTextFilter="clearTextFilter"
            @clearAllFilters="clearAllFilters"
        >
        </document-management>
        <input
            class="
                file-upload-container
            "
            type="file"
            ref="fileUploadContainer"
            accept=".xlsx, .xls"
            hidden
        />
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="2000"
            @close="showNotification = false"
        ></notification-component>
        <loader-component v-if="showLoader"></loader-component>
    </div>
</template>

<script>
import get from "lodash/get";
import { mapGetters, mapState } from "vuex";
import store from "@/store";
import { DOCUMENTMANAGEMENT_ACTIONS } from "@/store/modules/documentManagement/contants";
import DocumentManagement from "../components/DocumentManagement.vue";
import NotificationComponent from "../../../ui_component/notificationComponent.vue";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";

export default {
    name: "document-management-container",
    beforeCreate() {
        this.$store = store;
    },
    components: {
        DocumentManagement,
        NotificationComponent,
        LoaderComponent
    },
    data() {
        return {
            documentManagementRow: [],
            totalData: 0,
            page: 1,
            pageSize: 10,
            showLoader: false,
            //sorting
            sortType: false,
            sortOrder: -1,
            sortColIndex: 2,
            orderByColumn: "addedat",
            //Notification
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            clearCheckboxFilter: false,
            selectedToDate: "",
            selectedFromDate: "",

            documentManagementHeader: [
                {
                    fieldName: "filename",
                    text: "File Name",
                    width: "40%",
                    sortable: true,
                },
                {
                    fieldName: "username",
                    text: "Imported By",
                    width: "30%",
                    sortable: true,
                },
                {
                    fieldName: "addedat",
                    text: "Imported Date",
                    width: "20%",
                    sortable: true,
                    sorted: -1,
                },
                {
                    fieldName: "actions",
                    text: "Actions",
                    width: "10%",
                    align: "right",
                },
            ],

            counterDetails: {
                displayCounterDetails: false,
                count: 0,
                countText: "Selected",
                showVerticalLine: false,
            },

            buttonPanelDetails: [
                {
                    id: "AddButtonId",
                    buttonName: "Upload File",
                    iconName: "plus",
                    iconColor: "defaultColor",
                    isDisabled: false,
                    showVerticalLine: false,
                    isText: false,
                    permission: "doc-management-header-upload"
                },
            ],

            filterObject: [
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
            ],
            paginationSearchParameters:[],
            uploadedFile: [],
            selectDateRangeValue:[],
            searchTextValue:[],
            paginationComponentKey:0
        };
    },
    props: {
        menuwidth: {
            type: String,
        },
    },
    watch: {
        clearCheckboxFilter() {
            this.clearCheckboxFilter = false;
        }
    },

    computed: {
        ...mapGetters(["cachedDispatch"]),
        ...mapState({
            documentManagementList(state) {
                return get(state, "documentManagement.documentManagement.documentManagement", []);
            },
            documentManagementListProgress(state) {
                return get(
                    state,
                    "documentManagement.documentManagement.__loading__",
                    false
                );
            },
            downloadDocumentManagementResponse(state) {
                return get(
                    state,
                    "documentManagement.downloadDocumentManagement.downloadDocumentManagement",
                    []
                );
            },
            uploadDocumentManagementResponse(state) {
                return get(
                    state,
                    "documentManagement.uploadDocumentManagement.uploadDocumentManagement",
                    []
                );
            },
            uploadDocumentManagementError(state) {
                return get(
                    state,
                    "documentManagement.uploadDocumentManagement.__error__",
                    null
                );
            },
        }),

    },

    created() {
        this.commonFunction();
    },

    methods: {
        openFilterPanel(selectedFilter) {
            console.log(selectedFilter);
            this.getDateRangeFilter(); 
        },
        
        getDateRangeFilter() {
            this.filterObject[0].filterHeader = "Date Imported By";
            this.filterObject[0].selectionType = "daterange";
            
            const selctedFromDate = this.selectedFromDate ? new Date(this.selectedFromDate) : this.selectedFromDate;
            const selctedToDate = this.selectedToDate ? new Date(this.selectedToDate) : this.selectedToDate
            
              this.filterObject[0].filterList = [
                {
                    id: 1,
                    isdisabled: false,
                    lable: "from date",
                    value: selctedFromDate,
                    minMaxDates: {
                        min: new Date(2000, 0, 1),
                        max: new Date(2049, 11, 31),
                    },
                },
                {
                    id: 2,
                    isdisabled: false,
                    lable: "to date",
                    value: selctedToDate,
                    minMaxDates: {
                        min: new Date(2000, 0, 1),
                        max: new Date(2049, 11, 31),
                    },
                },
            ];
        },

        dateRange(fromDate, toDate) {
            this.page = 1;
            this.selectedFromDate = fromDate.toLocaleDateString();
            this.selectedToDate = toDate.toLocaleDateString();
            let temp = this.selectedFromDate.split("/");
            if (temp[0].length == 1) {
                temp[0] = "0" + temp[0];
            }
            if (temp[1].length == 1) {
                temp[1] = "0" + temp[1];
            }
            let fromdate = temp[2] + "-" + temp[0] + "-" + temp[1];

            
            let temp1 = this.selectedToDate.split("/");
            if (temp1[0].length == 1) {
                temp1[0] = "0" + temp1[0];
            }
            if (temp1[1].length == 1) {
                temp1[1] = "0" + temp1[1];
            }
            let todate = temp1[2] + "-" + temp1[0] + "-" + temp1[1];
            this.selectDateRangeValue=[fromdate+"T00:00:00.000",todate+"T23:59:59.000"]
            
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

        paginateTable(arg) {
            this.page = arg.page;
            this.pageSize = arg.pageSize;
            this.commonFunction();
        },

        async commonFunction() {
            this.paginationSearchParameters= [
                {
                    SearchColumnNames: [ "filename","username" ],
                    ComparisionType: 1,
                    SearchValues: this.searchTextValue
                },
                {
                    SearchColumnName: "addedat",
                    ComparisionType: 3,
                    SearchValues: this.selectDateRangeValue,
                }
            ]
            var data = {
                page: this.page,
                pageSize: this.pageSize,
                orderByColumnName: this.orderByColumn,
                isAscending: this.sortType,
                paginationSearchParameters:this.paginationSearchParameters,
            };
            await this.$store.dispatch(DOCUMENTMANAGEMENT_ACTIONS.FETCH_DOCUMENTMANAGEMENT_LIST,{
                    data,
            });
            let response = this.documentManagementList;
            this.documentManagementRow = response.list;
            if(this.documentManagementRow.length == 0){
                this.documentManagementRow = undefined
            }
            this.totalData = response.totalitems;
        },

        //row action button
        async rowDownloadActionButton(value) {
            let data = value.fileid;
            await this.$store.dispatch(
                DOCUMENTMANAGEMENT_ACTIONS.FETCH_DOWNLOAD_DOCUMENTMANAGEMENT,
                {
                    data,
                }
            );
            let response = this.downloadDocumentManagementResponse;
            if (response.data !== undefined) {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${value.filename}`);
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(link.href);
            }
        },

        //top action button
        onClickPanelButton(val) {
            if (val === "Upload File") {
                this.uploadFile();
            }
        },

        uploadFile() {
            this.uploadedFile = [];
            let fileInputElement = this.$refs.fileUploadContainer;
            fileInputElement.addEventListener(
                "change",
                this.fileHandler,
                false
            );
            fileInputElement.click();
        },

        async fileHandler() {
            this.showLoader = true;
            this.uploadedFile = this.$refs.fileUploadContainer.files[0];
            let formData = new FormData();
            formData.append('file', this.uploadedFile);
            await this.$store.dispatch(
                DOCUMENTMANAGEMENT_ACTIONS.FETCH_UPLOAD_DOCUMENTMANAGEMENT,
                {
                    data : formData
                }
            );
            let response = this.uploadDocumentManagementResponse;
            const object = {
                response,
                ntfnMsg: response.message,
                ntfnType: "success",
                ntfnKey: "success-default",
                ntfnfailureMsg: this.uploadDocumentManagementError
            };
            this.notificationResponse(object);
        },

        reset() {
            this.searchTextValue = [];
            this.selectedFromDate = "";
            this.selectedToDate = "";
            this.selectDateRangeValue = [];
            this.getDateRangeFilter();
        },

        searchFilter(searchText) {
            this.page = 1;
            this.searchTextValue.push(searchText);
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

        clearTextFilter() {
            this.page=1
            this.searchTextValue= []; 
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

        clearAllFilters() {
            this.clearCheckboxFilter = true;
            this.reset();
            this.clearTextFilter();
        },

        //sorting

        executeSortingLogicForMdsTable(colIndex) {
            this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

            if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
                this.$set(this.documentManagementHeader[this.sortColIndex], "sorted", 0);
                this.sortOrder = -1;
            }

            this.sortColIndex = colIndex;
            this.$set(this.documentManagementHeader[colIndex], "sorted", this.sortOrder);
        },

        handleSortEvent(colIndex, colName) {
            this.page = 1;
            this.executeSortingLogicForMdsTable(colIndex);
            this.orderByColumn = colName;
            this.sortOrder == 1
                ? (this.sortType = true)
                : (this.sortType = false);
            this.commonFunction();
            this.paginationComponentKey += 1
        },

        notificationResponse({
            response,
            ntfnMsg,
            ntfnType,
            ntfnKey,
            ntfnfailureMsg,
        }) {
            if (response.statuscode == 200) {
                this.showLoader = false;
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.reset();
                setTimeout(() => {
                   this.page = 1;
                    this.commonFunction();
                    this.paginationComponentKey += 1;     
                }, 2000);                
            } else {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = ntfnfailureMsg;
                this.notificationType = "error";
                this.showNotification = true;
            }
        },
    },
};
</script>

