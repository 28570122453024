import { render, staticRenderFns } from "./DocumentManagement.vue?vue&type=template&id=2adc5a25&scoped=true&"
import script from "./DocumentManagement.vue?vue&type=script&lang=js&"
export * from "./DocumentManagement.vue?vue&type=script&lang=js&"
import style0 from "./DocumentManagement.vue?vue&type=style&index=0&id=2adc5a25&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2adc5a25",
  null
  
)

export default component.exports