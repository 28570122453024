var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{isFilterOpen: _vm.checkFilterOpen},attrs:{"id":"documentManagement"}},[(_vm.documentManagementListProgress)?_c('loader-component'):_vm._e(),_c('mds-layout-grid',[_c('mds-row',{staticStyle:{"margin":"0","padding":"0"}},[_c('div',{class:[
                    { showFilterLeftBlock: _vm.showFilterPanel },
                    'leftBlock' ],style:(_vm.cssVariables)},[_c('div',{staticClass:"documentManagementHeaderSection"},[_c('div',[_c('h2',{staticClass:"documentManagement-header"},[_vm._v("Document Management")])]),_c('div',{staticClass:"headerActionButton",staticStyle:{"margin-top":"25px"}},[_c('header-button-panel',{attrs:{"buttonDetails":_vm.buttonPanelDetails,"counterDetails":_vm.counterDetails,"sourceFile":"documentManagement"},on:{"panelButtonClicked":_vm.onClickPanelButton}})],1)]),_c('div',{staticClass:"documentManagementTable"},[_c('div',{staticClass:"documentManagement_table"},[(_vm.documentManagementLength > 0)?_c('mds-table',{attrs:{"row-hover":"","show-sortable":""}},[_c('mds-thead',_vm._l((_vm.documentManagementHeader),function(header,index){return _c('mds-th',{key:index,attrs:{"sortable":header.sortable,"sorted":header.sorted,"width":header.width,"right-aligned":header.align === 'right'},on:{"mds-th-sort-changed":function($event){return _vm.handleSortEvent(
                                            index,
                                            header.fieldName,
                                            $event
                                        )}}},[_vm._v(" "+_vm._s(header.text)+" ")])}),1),_c('mds-tbody',_vm._l((_vm.documentManagementRow),function(row,index){return _c('mds-tr',{key:index},_vm._l((_vm.documentManagementHeader),function(header,i){return _c('mds-td',{key:i,attrs:{"right-aligned":header.align === 'right'}},[(
                                                header.fieldName ===
                                                'actions'
                                            )?[_c('span',[_c('mds-button',{staticClass:"actionButton",attrs:{"disabled":!(_vm.permissions.includes('doc-management-header-download') || _vm.permissions.includes('*')),"icon":"download","variation":"icon-only","type":"button"},on:{"click":function($event){return _vm.rowDownloadActionButton(
                                                            row
                                                        )}}})],1)]:(
                                                header.fieldName ===
                                                'addedat'
                                            )?[_vm._v(" "+_vm._s(_vm.convertAdditionalSearchDate( row[header.fieldName] ))+" ")]:[_vm._v(" "+_vm._s(row[header.fieldName])+" ")]],2)}),1)}),1)],1):_vm._e()],1),(_vm.documentManagementLength > 0)?_c('mds-pagination',{key:_vm.paginationComponentKey,class:[
                            _vm.showFilterPanel === true
                                ? 'move-pagination-show-info'
                                : '',
                            'pagination-component' ],staticStyle:{"margin-top":"10px","margin-right":"3px"},attrs:{"show-items-info":"","show-items-select":"","total-items":_vm.totalItems,"pageSize":_vm.pageSize,"pageSizes":[10, 20, 50]},on:{"mds-pagination-page-changed":_vm.paginateTable}}):_vm._e(),(_vm.documentManagementRow == undefined)?_c('mds-empty-state',{staticClass:"no-results-message",attrs:{"size":"medium","title":"No results matched","message":"We weren't able to match any results with your current selections. Please adjust your filter criteria and try again."}}):_vm._e()],1)]),_c('div',{class:[
                    { showFilterRightBlock: _vm.showFilterPanel },
                    'rightBlock' ],style:(_vm.cssVariables)},[_c('div',{staticClass:"filterPanel"},[_c('transition-group',{staticClass:"group-4",attrs:{"name":"fade"}},[_c('filter-panel',{key:_vm.checkBoxFilterKey,attrs:{"filterData":_vm.filterObject,"isFilterPanelOpen":_vm.showFilterPanel,"searchInputPlaceHolder":"Search by user or file name","showSearchInput":true},on:{"filterEvent":_vm.searchFilter,"dateRange":_vm.dateRange,"hideFilterPanel":_vm.closeFilterPanel,"showFilterPanel":_vm.openFilterPannel,"clearInputFilter":_vm.clearTextFilter,"clearAllFilters":_vm.clearAllFilters}})],1)],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }